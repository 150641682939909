import { IFilterUsersRequest } from '@/interfaces/users/user.interface';
import { ACCOUNTS_STATUS, BOOLEAN_FILTER, SEARCH_ALL_VALUE } from '@/utils/constants';
import { makeAutoObservable } from 'mobx';

export class AccountStore {
	filterOptionState: IFilterUsersRequest = {
		accountStatuses: [ACCOUNTS_STATUS.All],
		backOfficeAdministrationAccessIds: SEARCH_ALL_VALUE,
		brandIds: SEARCH_ALL_VALUE,
		countryIds: SEARCH_ALL_VALUE,
		frontOfficeRoleIds: SEARCH_ALL_VALUE,
		hasAccessToFrontOffice: [BOOLEAN_FILTER.true],
		regionIds: SEARCH_ALL_VALUE,
		sentWelcomeEmail: [BOOLEAN_FILTER.all],
		workplaceIds: SEARCH_ALL_VALUE,
		communityIds: SEARCH_ALL_VALUE,
		textSearch: '',
	};

	constructor() {
		makeAutoObservable(this);
	}

	async setFilterOptionState(filterOptionState: IFilterUsersRequest) {
		return (this.filterOptionState = filterOptionState);
	}

}

export const accountStore = new AccountStore();
