import { makeAutoObservable } from 'mobx';
import {
	IGetCourseStatusResponse, IGetCourseTypesResponse
} from './../../interfaces/courses/courses.interface';

export class CoursesStore {
	isFetchDataForm: boolean = false;

	courseTypes: IGetCourseTypesResponse[] = [];

	courseStatus: IGetCourseStatusResponse[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	async setIsFetchDataForm(isFetchDataForm: boolean) {
		return (this.isFetchDataForm = isFetchDataForm);
	}

	async setCourseTypes(courseTypes: IGetCourseTypesResponse[]) {
		return (this.courseTypes = courseTypes);
	}

	async setCourseStatus(courseStatus: IGetCourseStatusResponse[]) {
		return (this.courseStatus = courseStatus);
	}
}

export const coursesStore = new CoursesStore();
