import { makeAutoObservable } from 'mobx';

export class CommunitiesStore {
	selectedCommunity: any = {
		communityName: '',
		numOfAccounts: 0,
		status: '',
	};

	constructor() {
		makeAutoObservable(this);
	}

	async setName(name: string) {
		return (this.selectedCommunity.communityName = name);
	}

	async setNumberOfAccounts(numOfAccounts: number) {
		return (this.selectedCommunity.numOfAccounts = numOfAccounts);
	}

	async setStatus(status: string) {
		return (this.selectedCommunity.status = status);
	}
}

export const communitiesStore = new CommunitiesStore();
