import Access from '@/components/general/Access';
import { getPath as _ } from '@/helpers/getPath';
import { ADMINISTRATION_ACCESS_TYPE } from '@/utils/constants';
import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { ROUTES_NAME } from './routesName';
/* -------------------------------------------------------------------------- */
/*                                 MAIN LAYOUT                                 */
/* -------------------------------------------------------------------------- */
const MainLayout = React.lazy(() => import('@/components/layouts/Main') as any);
/* -------------------------------------------------------------------------- */
/*                                    USERS                                   */
/* -------------------------------------------------------------------------- */
const UsersPage = React.lazy(() => import('@/pages/Users') as any);
const AccountTab = React.lazy(() => import('@/pages/Users/Accounts'));
const CommunitiesTab = React.lazy(() => import('@/pages/Users/Communities'));
const CommunitiesActionsTab = React.lazy(() => import('@/pages/Users/Communities/Actions'));
const AccountActionsTab = React.lazy(() => import('@/pages/Users/Accounts/Actions'));
const ReportTab = React.lazy(() => import('@/pages/Users/Accounts/Report'));

/* -------------------------------------------------------------------------- */
/*                                  SETTINGS                                  */
/* -------------------------------------------------------------------------- */
const SettingsPage = React.lazy(() => import('@/pages/Settings') as any);
const GeographyTab = React.lazy(() => import('@/pages/Settings/Geography'));
const GeographyActionsTab = React.lazy(() => import('@/pages/Settings/Geography/Actions'));
const RolesTab = React.lazy(() => import('@/pages/Settings/Roles'));
const RolesActionsTab = React.lazy(() => import('@/pages/Settings/Roles/Actions') as any);
const ChannelsTab = React.lazy(() => import('@/pages/Settings/Channels'));
const ChannelsActionsTab = React.lazy(() => import('@/pages/Settings/Channels/Actions') as any);
const ThematicsTab = React.lazy(() => import('@/pages/Settings/Thematics'));
const ThematicsActionsTab = React.lazy(() => import('@/pages/Settings/Thematics/Actions') as any);
const PrintedCertificateTab = React.lazy(
	() => import('@/pages/Settings/PrintedCertificate') as any
);
const PrintedCertificatePaymentTab = React.lazy(
	() => import('@/pages/Courses/Actions/SetupPayment') as any
);
const DiscountTab = React.lazy(() => import('@/pages/Settings/Discount') as any);
const DiscountActionsTab = React.lazy(() => import('@/pages/Settings/Discount/Actions') as any);

/* -------------------------------------------------------------------------- */
/*                             MANAGE FRONT OFFICE                            */
/* -------------------------------------------------------------------------- */
const ManageFrontOfficePage = React.lazy(() => import('@/pages/ManageFrontOffice') as any);
const Arborescence = React.lazy(() => import('@/pages/ManageFrontOffice/Arborescence') as any);
const ActionsArborescenceTab = React.lazy(
	() => import('@/pages/ManageFrontOffice/Arborescence/Actions') as any
);
const ContentLocationTab = React.lazy(
	() => import('@/pages/ManageFrontOffice/ContentLocation') as any
);
/* -------------------------------------------------------------------------- */
/*                                   COURSES                                  */
/* -------------------------------------------------------------------------- */
const CoursesPage = React.lazy(() => import('@/pages/Courses') as any);
const CoursesActionsTab = React.lazy(() => import('@/pages/Courses/Actions') as any);
const CoursePaymentTab = React.lazy(() => import('@/pages/Courses/Actions/SetupPayment') as any);
/* -------------------------------------------------------------------------- */
/*                                  CLASSROOM                                 */
/* -------------------------------------------------------------------------- */
const ClassroomPage = React.lazy(() => import('@/pages/Classroom') as any);
const ClassroomActionsPage = React.lazy(() => import('@/pages/Classroom/Actions') as any);
const ClassroomPaymentTab = React.lazy(() => import('@/pages/Courses/Actions/SetupPayment') as any);

/* -------------------------------------------------------------------------- */
/*                                 TRANSLATION                                */
/* -------------------------------------------------------------------------- */
const TranslationPage = React.lazy(() => import('@/pages/Translation') as any);
const TranslationActionsTab = React.lazy(() => import('@/pages/Translation/Actions') as any);

/* -------------------------------------------------------------------------- */
/*                                 STATISTICS                                 */
/* -------------------------------------------------------------------------- */
const StatisticsPage = React.lazy(() => import('@/pages/Statistics') as any);
const KPISPage = React.lazy(() => import('@/pages/Statistics/Kpis') as any);
const DatabasePage = React.lazy(() => import('@/pages/Statistics/Database') as any);

const LoginPage = React.lazy(() => import('@/pages/Login') as any);
const NotFoundPage = React.lazy(() => import('@/pages/404') as any);
const SelectEntityPage = React.lazy(() => import('@/pages/Login/SelectEntity') as any);

/* ------------------------------- OIDC LOGIN ------------------------------- */
const OidcLogin = React.lazy(() => import('@/pages/Login/OidcLogin') as any);

const {
	users,
	accounts,
	communities,
	courses,
	settings,
	manageFrontOffice,
	statistics,
	storybook,
	geography,
	channels,
	roles,
	thematics,
	printedCertificate,
	discount,
	actions,
	arborescence,
	contentLocation,
	login,
	classroom,
	notFound,
	payment,
	selectEntity,
	oidcLogin,
	report,
	loginComplete,
	translation,
	kpis,
	database,
} = ROUTES_NAME;

const routers: RouteObject[] = [
	{
		path: '/',
		element: <MainLayout />,
		children: [
			/* -------------------------------------------------------------------------- */
			/*                                    USERS                                   */
			/* -------------------------------------------------------------------------- */
			{ index: true, element: <Navigate to={_(users)} replace /> },
			{
				path: _(users),
				element: <UsersPage />,
				children: [
					{ index: true, element: <Navigate to={_(users, accounts)} replace /> },
					{
						path: _(users, accounts),
						element: <AccountTab />,
					},
					{
						path: _(users, communities),
						element: <CommunitiesTab />,
					},
				],
			},
			{
				path: _(users, communities, actions),
				element: <CommunitiesActionsTab />,
			},
			{
				path: _(users, accounts, actions),
				element: <AccountActionsTab />,
			},
			{
				path: _(users, accounts, report),
				element: <ReportTab />,
			},
			/* -------------------------------------------------------------------------- */
			/*                                  SETTINGS                                  */
			/* -------------------------------------------------------------------------- */
			{
				path: _(settings),
				element: <SettingsPage />,
				children: [
					{
						index: true,
						element: <Navigate to={_(settings, geography)} replace />,
					},
					{
						path: _(settings, geography),
						element: <GeographyTab />,
					},
					{
						path: _(settings, roles),
						element: (
							<Access
								backOfficeAdministrationAccessType={[ADMINISTRATION_ACCESS_TYPE.InternationalAdmin]}
								fallbackComponent={<Navigate to={_(notFound)} replace />}
							>
								<RolesTab />
							</Access>
						),
					},
					{
						path: _(settings, channels),
						element: (
							<Access
								backOfficeAdministrationAccessType={[ADMINISTRATION_ACCESS_TYPE.InternationalAdmin]}
								fallbackComponent={<Navigate to={_(notFound)} replace />}
							>
								<ChannelsTab />
							</Access>
						),
					},
					{
						path: _(settings, thematics),
						element: (
							<Access
								backOfficeAdministrationAccessType={[ADMINISTRATION_ACCESS_TYPE.InternationalAdmin]}
								fallbackComponent={<Navigate to={_(notFound)} replace />}
							>
								<ThematicsTab />
							</Access>
						),
					},
					{
						path: _(settings, printedCertificate),
						element: <PrintedCertificateTab />,
					},
					{
						path: _(settings, discount),
						element: <DiscountTab />,
					},
				],
			},
			{
				path: _(settings, geography, actions),
				element: <GeographyActionsTab />,
			},
			{
				path: _(settings, roles, actions),
				element: <RolesActionsTab />,
			},
			{
				path: _(settings, channels, actions),
				element: <ChannelsActionsTab />,
			},
			{
				path: _(settings, thematics, actions),
				element: <ThematicsActionsTab />,
			},
			{
				path: _(settings, printedCertificate, payment),
				element: <PrintedCertificatePaymentTab />,
			},
			{
				path: _(settings, discount, actions),
				element: <DiscountActionsTab />,
			},
			/* -------------------------------------------------------------------------- */
			/*                             MANAGE FRONT OFFICE                            */
			/* -------------------------------------------------------------------------- */
			{
				path: _(manageFrontOffice),
				element: <ManageFrontOfficePage />,
				children: [
					{
						index: true,
						element: (
							<Access
								backOfficeAdministrationAccessType={[ADMINISTRATION_ACCESS_TYPE.InternationalAdmin]}
								fallbackComponent={<Navigate to={_(manageFrontOffice, contentLocation)} replace />}
							>
								<Navigate to={_(manageFrontOffice, arborescence)} replace />
							</Access>
						),
					},

					{
						path: _(manageFrontOffice, arborescence),
						element: (
							<Access
								backOfficeAdministrationAccessType={[ADMINISTRATION_ACCESS_TYPE.InternationalAdmin]}
								fallbackComponent={<Navigate to={_(notFound)} replace />}
							>
								<Arborescence />
							</Access>
						),
					},
					{
						path: _(manageFrontOffice, contentLocation),
						element: <ContentLocationTab />,
					},
				],
			},
			{
				path: _(manageFrontOffice, arborescence, actions),
				element: <ActionsArborescenceTab />,
			},
			/* -------------------------------------------------------------------------- */
			/*                                   COURSES                                  */
			/* -------------------------------------------------------------------------- */
			{
				path: _(courses),
				element: <CoursesPage />,
			},
			{
				path: _(courses, actions),
				element: <CoursesActionsTab />,
			},
			{
				path: _(courses, payment),
				element: <CoursePaymentTab />,
			},

			/* -------------------------------------------------------------------------- */
			/*                                 CLASSROOMS                                 */
			/* -------------------------------------------------------------------------- */
			{
				path: _(classroom),
				element: <ClassroomPage />,
			},
			{
				path: _(classroom, actions),
				element: <ClassroomActionsPage />,
			},
			{
				path: _(classroom, payment),
				element: <ClassroomPaymentTab />,
			},
			/* -------------------------------------------------------------------------- */
			/*                                 TRANSLATIONS                              */
			/* -------------------------------------------------------------------------- */
			{
				path: _(translation),
				element: <TranslationPage />,
			},
			{
				path: _(translation, actions),
				element: <TranslationActionsTab />,
			},
			/* -------------------------------------------------------------------------- */
			/*                                 STATISTICS                                 */
			/* -------------------------------------------------------------------------- */
			{
				path: _(statistics),
				element: <StatisticsPage />,
				children: [
					{
						index: true,
						element: <Navigate to={_(statistics, kpis)} replace />,
					},
					{
						path: _(statistics, kpis),
						element: <KPISPage />,
					},
					{
						path: _(statistics, database),
						element: <DatabasePage />,
					},
				],
			},
			/* -------------------------------------------------------------------------- */
			/*                                OTHER ROUTES                                */
			/* -------------------------------------------------------------------------- */
			{
				path: _(storybook),
			},
		],
	},
	/* -------------------------------------------------------------------------- */
	/*                                    LOGIN                                   */
	/* -------------------------------------------------------------------------- */
	{
		path: _(login),
		element: <LoginPage />,
	},
	{
		path: _(login, selectEntity),
		element: <SelectEntityPage />,
	},
	{
		path: _(notFound),
		element: <NotFoundPage />,
	},
	/* ----------------------------- REDIRECT LOGIN ----------------------------- */
	{
		path: _(oidcLogin, loginComplete),
		element: <OidcLogin />,
	},
	/* ---------------------------- NOT ACCESS SYSTEM --------------------------- */
	{
		path: '/.well-known/assetlinks.json',
	},
	{
		path: '*',
		element: <Navigate to={_(notFound)} replace />,
	},
];

export default routers;
