import { makeAutoObservable } from 'mobx';
import { IClassStatus } from '@/interfaces/classroom/classroom.interface';

export class ClassroomStore {
	constructor() {
		makeAutoObservable(this);
	}

	classStatuses: IClassStatus[] = [];

	async setClassStatuses(classStatuses: IClassStatus[]) {
		return (this.classStatuses = classStatuses);
	}
}

export const classroomStore = new ClassroomStore();
