import { makeAutoObservable } from 'mobx';

interface IIndexSelectedGeography {
	region: number,
	country: number,
	workplace: number,
}
export class GeographyStore {
	regionNameEditing = '';

	indexSelectedGeography  = <IIndexSelectedGeography>{
		region: 0,
		country: 0,
		workplace:  0,
	}

	constructor() {
		makeAutoObservable(this);
	}

	async setRegionNameEditing(regionName: string) {
		return (this.regionNameEditing = regionName);
	}

	async getRegionNameEditing() {
		return this.regionNameEditing;
	}

	async setIndexSelectedGeography(index: IIndexSelectedGeography) {
		return (this.indexSelectedGeography = index);
	}

	async getIndexSelectedGeography() {
		return this.indexSelectedGeography;
	}

}

export const geographyStore = new GeographyStore();
